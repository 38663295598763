@import "../style.scss";

.ContactButton {
    color: $cream;
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}


a:focus, a:hover {
    .ContactButton {
        mix-blend-mode: lighten;
    }
}

.ContactButton>img {
    width: 92px;

    @media (width < 800px) {
        width: 72px;
    }
}