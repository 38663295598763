@import "../style.scss";

.Experience {
	display: grid;

    padding: 32px 48px;
    gap: 16px 48px;

	grid-template-columns: 150px auto;
	grid-template-areas:
		"a b"
		"a c";

	@media (width < 800px) {
        padding: 32px 32px;
        gap: 16px 16px;

		grid-template-columns: 64px auto;
		grid-template-areas:
			"a b"
			"c c";
	}


	border-radius: 6px;
	box-shadow: 6px 6px 16px rgba($black, 0.25);
}

.Experience #experienceImage > img {
	max-width: 150px;

	@media (width < 800px) {
		max-width: 64px;
	}
}

.Experience #experienceImage {
	grid-area: a;
    display: flex;
}

.Experience #experienceTitle {
	grid-area: b;

    display: flex;
    flex-direction: column;
    align-self: center;

    gap: 4px;
}

.Experience #experienceDescription {
	grid-area: c;
}
