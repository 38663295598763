@import "../style.scss";

.About {
    background-color: $cream;
    display: flex;
    flex-direction: column;
    
    margin-top: 100vh;

    padding-top: 160px;
    padding-bottom: 160px;
    gap: 64px;

    @media (width < 800px) {
        padding-left: 5%;
        padding-right: 5%;

        padding-top: 64px;
        padding-bottom: 64px;
        gap: 32px;
    }

    @media (width >=800px) {
        padding-left: 10%;
        padding-right: 10%;
    }

    @media (width >=1200px) {
        padding-left: 15%;
        padding-right: 15%;
    }

    @media (width >=1800px) {
        padding-left: 20%;
        padding-right: 20%;
    }
}