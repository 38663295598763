$font: "Helvetica Neue", Helvetica, sans-serif;

$blue: #5E2BFF;
$cream: #FFF7EB;
$black: #3F3930;

body {
    margin: 0;
    font-family: $font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    font: $font;

    font-weight: 800;
    letter-spacing: -1%;
    line-height: 90%;

    margin: 0;

    @media (width < 800px) {
        font-size: 64px;
    }

    @media (width >= 800px) {
        font-size: 92px;
    }

    @media (width >= 1800px) {
        font-size: 126px;
    }
}

h2 {
    font: $font;
    font-weight: 800;
    line-height: 90%;

    margin: 0;

    @media (width < 800px) {
        font-size: 20px;
    }

    @media (width >= 800px) {
        font-size: 32px;
    }

    @media (width >= 1800px) {
        font-size: 40px;
    }
}

h3 {
    font: $font;
    font-weight: 400;    

    margin: 0;

    @media (width < 800px) {
        font-size: 16px;
    }

    @media (width >= 800px) {
        font-size: 24px;
    }

    @media (width >= 1800px) {
        font-size: 32px;
    }
}

p {
    font: $font;
    font-weight: 400;

    margin: 0;

    @media (width < 800px) {
        font-size: 12px;
    }

    @media (width >= 800px) {
        font-size: 16px;
    }

    @media (width >= 1800px) {
        font-size: 20px;
    }
}

a {
    font: $font;
    text-decoration: none;
    font-weight: 800;
    
    @media (width < 800px) {
        font-size: 12px;
    }

    @media (width >= 800px) {
        font-size: 16px;
    }

    @media (width >= 1800px) {
        font-size: 24px;
    }
}

