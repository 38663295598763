@import "../style.scss";

.Home {
  background-color: $blue;
  color: $cream;

  display: flex;
  position: absolute;
  width: 100%;
  height: 100vh;

  justify-content: center;
  align-items: center;

  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: -99;
}

.Home #container {
  display: flex;
  gap: 32px;

  @media (width < 800px) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media (width >=800px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (width >=1200px) {
    padding-left: 15%;
    padding-right: 15%;
  }

  @media (width >=1800px) {
    padding-left: 20%;
    padding-right: 20%;
  }
}

.Home #text {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-self: center;
  gap: 16px;

}

.Home #hero {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (width < 800px) {
    display: none;
  }

  @media (width >=800px) {
    max-width: 300px;
  }

  @media (width >=1800px) {
    max-width: 400px;
  }
}

.Home #hero>img {
  mix-blend-mode: luminosity;

  @media (width < 1800px) {
    width: 600px;
  }

  @media (width >=1800px) {
    width: 800px;
  }
}