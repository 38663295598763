@import '../style.scss';

.Contact {
    background-color: $blue;
    color: $cream;

    padding-top: 160px;
    padding-bottom: 160px;

    display: flex;
    flex-direction: column;
    gap: 64px;

    @media (width < 800px) {
        padding-top: 64px;
        padding-bottom: 64px;

        padding-left: 5%;
        padding-right: 5%;

        gap: 32px;
    }

    @media (width >=800px) {
        padding-left: 10%;
        padding-right: 10%;
    }

    @media (width >=1200px) {
        padding-left: 15%;
        padding-right: 15%;
    }

    @media (width >=1800px) {
        padding-left: 20%;
        padding-right: 20%;
    }
}

.Contact > h1 {
    @media (width < 800px) {
        display: none;
    }
}

.Contact #buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 64px;

    @media (width < 800px) {
        gap: 32px;
        align-self: center;
    }
}